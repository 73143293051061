import { values } from 'lodash';
import validator from 'validator';
export const houseTypeList = [
  {
    label: '办公室',
    value: 1,
  },
  {
    label: '厂房',
    value: 2,
  },
  {
    label: '其他',
    value: 3,
  },
];

export const towardList = [
  {
    label: '南北',
    value: 1,
  },
  {
    label: '南',
    value: 2,
  },
  {
    label: '东',
    value: 3,
  },
  {
    label: '西',
    value: 4,
  },
  {
    label: '北',
    value: 5,
  },
  {
    label: '其他',
    value: 6,
  },
];
export const renovationList = [
  {
    label: '毛坯',
    value: 1,
  },
  {
    label: '简单装修',
    value: 2,
  },
  {
    label: '精装',
    value: 3,
  },
];
export const publishStatusList = [
  {
    label: '待发布',
    value: 0,
  },
  {
    label: '已下架',
    value: 1,
  },
  {
    label: '已发布',
    value: 2,
  },
];
export const housingStatusList = [
  {
    label: '锁定中',
    value: 1,
  },
  {
    label: '招商中',
    value: 2,
  },
  {
    label: '租赁中',
    value: 3,
  },
];
export const columns = [
  {
    title: '项目',
    key: 'projectName',
    dataIndex: 'projectName',
    width: '200px',
  },
  { title: '房源位置', key: 'housingLocation', dataIndex: 'housingLocation', ellipsis: true },
  { title: '房源名称', key: 'houseResourceName', dataIndex: 'houseResourceName', ellipsis: true },
  {
    title: '房源类型',
    key: 'houseType',
    dataIndex: 'houseType',
    customRender: (text, record) => {
      let val = '';
      if (text) {
        val = houseTypeList[Number(text) - 1].label;
      }
      return val;
    },
  },
  {
    title: '价格',
    key: 'price',
    dataIndex: 'price',
    customRender: (text, record) => {
      return text + '元/㎡/天';
    },
  },
  {
    title: '朝向',
    key: 'orientationType',
    dataIndex: 'orientationType',
    customRender: (text, record) => {
      let val = '-';
      if (text) {
        val = towardList[Number(text) - 1].label;
      }
      return val;
    },
  },
  {
    title: '装修情况',
    key: 'fitmentType',
    dataIndex: 'fitmentType',
    customRender: (text, record) => {
      let val = '';
      if (text) {
        val = renovationList[Number(text) - 1].label;
      }
      return val;
    },
  },
  {
    title: '状态',
    key: 'publishStatus',
    dataIndex: 'publishStatus',
    customRender: (text, record) => {
      let val = '';
      console.log(publishStatusList[Number(text)]);
      val = publishStatusList[Number(text)].label;
      return val;
    },
  },
  {
    title: '收藏量',
    key: 'count',
    dataIndex: 'count',
  },
  { title: '最近操作时间', key: 'updateTime', dataIndex: 'updateTime' },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
    width: '200px',
  },
];
export const housingResourcesRule = {};
export const fieldName = {
  value: 'id',
  key: 'id',
  title: 'project_name',
  children: 'child',
};
export const roomPlaceFieldName = {
  children: 'son_list',
  title: 'space_name',
  key: 'id',
  value: 'id',
};
// 场地列表-添加场地-富文本编辑器配置选项
export const editorOption = {
  placeholder: '请在这里输入',
  modules: {
    toolbar: [
      ['italic', 'underline', 'strike'],
      ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
      ['blockquote', 'code-block'], //引用，代码块
      [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
      [{ list: 'ordered' }, { list: 'bullet' }], //列表
      [{ script: 'sub' }, { script: 'super' }], // 上下标
      [{ indent: '-1' }, { indent: '+1' }], // 缩进
      [{ direction: 'rtl' }], // 文本方向
      [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
      [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
      [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
      [{ font: [] }], //字体
      [{ align: [] }], //对齐方式
      ['clean'], //清除字体样式
      ['image'], //上传图片、上传视频
    ],
  },
};

export const structureTypeList = [
  {
    label: '钢结构',
    value: 1,
  },
  {
    label: '钢筋混凝土结构',
    value: 2,
  },
  {
    label: '砖混结构',
    value: 3,
  },
  {
    label: '框架结构',
    value: 4,
  },
  {
    label: '轻钢结构',
    value: 5,
  },
  {
    label: '其他',
    value: 6,
  },
];
export const selectColumns = [
  {
    title: '空间位置',
    key: 'spacePlace',
    dataIndex: 'spacePlace',
    ellipsis: true,
  },
  { title: '空间名称', key: 'spaceName', dataIndex: 'spaceName', ellipsis: true },
  {
    title: '房源状态',
    key: 'housingStatus',
    dataIndex: 'housingStatus',
    customRender: (text, record) => {
      let val = '';
      if (text) {
        val = housingStatusList[Number(text) - 1].label;
      }
      return val;
    },
  },
  {
    title: '楼栋类型',
    key: 'buildingType',
    dataIndex: 'buildingType',
    customRender: (text, record) => {
      let val = '';
      if (text) {
        val = houseTypeList[Number(text) - 1].label;
      }
      return val;
    },
  },
  {
    title: '建筑结构',
    key: 'structureType',
    dataIndex: 'structureType',
    customRender: (text, record) => {
      let val = '';
      if (text) {
        val = structureTypeList[Number(text) - 1].label;
      }
      return val;
    },
  },
  {
    title: '层高',
    key: 'storeyHeight',
    dataIndex: 'storeyHeight',
    customRender: (text, record) => {
      return text ? text + '米' : '';
    },
  },
  {
    title: '承重',
    key: 'bearing',
    dataIndex: 'bearing',
    customRender: (text, record) => {
      return text ? text + 'KN/㎡' : '';
    },
  },
  {
    title: '可租面积',
    key: 'rentableArea',
    dataIndex: 'rentableArea',
    customRender: (text, record) => {
      return text ? text + '米' : '';
    },
  },
  {
    title: '套内面积',
    key: 'insideSpace',
    dataIndex: 'insideSpace',
    customRender: (text, record) => {
      return text ? text + '米' : '';
    },
  },
  {
    title: '公摊面积',
    key: 'publicArea',
    dataIndex: 'publicArea',
    customRender: (text, record) => {
      return text ? text + '米' : '';
    },
  },
];
export function validatePhoneTwo(rule, value, callback) {
  const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
  if (value == '' || value == undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value != '') {
      callback(new Error('请输入正确的电话号码或者固话号码'));
    } else {
      callback();
    }
  }
}
export const addRules = {
  // spaceId: [{ required: true, validator: checkSpaceId,}],
  houseResourceName: [{ required: true, message: '请输入房源名称', trigger: 'blur' }],
  appointmentPhone: [
    { required: true, message: '请输入预约电话', trigger: 'blur' },
    {
      pattern: new RegExp(/^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/, 'g'),
      trigger: 'blur',
      message: '请输入正确的电话号码或者固话号码',
    },
  ],
  visibleType: [{ required: true, message: '请选择可见范围', trigger: 'change' }],
  fileList: [{ required: true, message: '请上传房源图片', trigger: 'change' }],
  price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
  onHireDate: [{ required: true, message: '请输入起租期', trigger: 'blur' }],
  fitmentType: [{ required: true, message: '请选择装修情况', trigger: 'change' }],
  // houseResourceDetails: [{ required: true, message: '请输入房源详情', trigger: 'blur' }],
  storeyHeight: [{ required: true, message: '请输入层高', trigger: 'blur' }],
  bearing: [{ required: true, message: '请输入承重', trigger: 'blur' }],
  address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
};
