import { fetchApi } from '@/utils/axios';
import store from '@/store';
const api = '/api/ds-operations-management';
// 房源列表
export const getHousingResourcesDataList = (data) => {
  return fetchApi(api + '/web/houseResource/list', data, 'GET');
};
// 房源详情
export const getHousingResourcesDetail = (data) => {
  return fetchApi(api + '/web/houseResource/detail/' + data, null, 'GET');
};
// 添加房源
export const addHousingResources = (data) => {
  return fetchApi(api + '/web/houseResource/add', data, 'POST', 'data');
};
// 更新房源
export const updateHousingResources = (data) => {
  return fetchApi(api + '/web/houseResource/update', data, 'POST', 'data');
};
// 房源状态修改
export const updateStatusHousingResources = (data) => {
  return fetchApi(api + '/web/houseResource/updateStatus', data, 'PUT', 'data');
};
// 删除房源
export const deleteHousingResources = (data) => {
  return fetchApi(api + '/web/houseResource/delete/' + data, null, 'DELETE');
};
// 获取项目
export const getProjectList = (data) => {
  return fetchApi(
    '/api/project/saas/project/userRole/treeProjectList',
    { role_id: store.state.common.currentMenuRoleId },
    'POST',
    'data'
  );
};
// 获取空间Tree
export const getSpaceInfoBySpaceIds = (data) => {
  return fetchApi('/api/space/space/getSpaceTreeByRoleId', data, 'GET');
};
// 空间通用组件
export const getSpaceCommonComponent = (data) => {
  return fetchApi('/api/space/space/getSpaceCommonComponent', data, 'GET');
};
// 根据roleId获取空间树状结构
export const getSpaceTreeByroleId = (data) => {
  return fetchApi('/api/space/space/getSpaceTreeByroleId', data, 'GET');
};
// 根据项目id获取所有子集空间信息
export const getSonSpaceListByProjectId = (data) => {
  return fetchApi('/api/space/space/getSonSpaceListByProjectId', data, 'GET');
};

